import { useEffect, useState, lazy, Suspense } from "react";
import {
  Route,
  useLocation,
  useNavigate,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import css from "./App.module.css";
import Header from "@Components/Header";
import { getUserDetails } from "./actions/userDetails";
import LicenceWarning from "./components/LicenceWarning/LicenceWarning";
import getHomePageUrl from "./services/hooks/getHomePageUrl";
import { getUserPermissions } from "./actions/userPermissions";
import { fetchLayout } from "./actions/layout";
import MinimizeFloatter from "@Components/MinimizeFloatter";
import { getPortalData } from "@Actions/portalSetting";
import * as Sentry from "@sentry/react";
import PaymentPlanWarning from "@Components/PaymentPlanWarning";
import OldBrowserWarning from "@Components/OldBrowserWarning";
import Notification from "./components/Notification";
import Welcome from "./pages/Welcome";
import SubscriptionWarning from "./pages/SubscriptionWarning";
import MobileDeviceRestrict from "@Components/MobileDeviceRestrict";
import { getCurrProjectData } from "./actions/breadcrumbsdata";
import UploadFileMinimiser from "@Components/FileManager/FileUpload/UploadFileMinimiser";
import axiosWrapper from "@Services/axiosWrapper";
import { getPortalObject } from "./actions/portalDashboard.js";
import StickySidebar from "./components/StickySidebar";
import { setShowStickySidebar } from "./actions/stickySidebar.js";
import ErrorBoundary from "./ErrorBoundary.js";

const DesignViewer = lazy(
  () => import("./components/DesignViewer/DesignViewer"),
);
const Issues = lazy(() => import("./components/Issues/Issues"));
const FileExplorer = lazy(() => import("./pages/FileExplorer"));
const MobileExplorer = lazy(() => import("./pages/FileExplorer/Mobile.jsx"));
const TaskDetails = lazy(
  () => import("./pages/TaskManagement/TaskDetails/TaskDetails"),
);
const TransmitalsList = lazy(
  () => import("./pages/Transmitals/ListTransmittals/listTransmittals"),
);
const TransmitalDetails = lazy(
  () => import("./pages/Transmitals/TransmitalDetails/TransmitalDetails"),
);
const TaskList = lazy(() => import("./pages/TaskManagement/ListTask/listTask"));
const Gantt = lazy(() => import("./components/Schedule/Gantt"));
const PortalDashboard = lazy(() => import("./components/PortalDashboard"));
const ProjectDashboard = lazy(() => import("./components/ProjectDashboard"));
const Transmitals = lazy(() => import("./pages/Transmitals/transmitals"));
const PortalSetting = lazy(
  () => import("./pages/PortalSettings/PortalSettings"),
);
const UserProfile = lazy(() => import("./pages/UserProfile"));
const ProjectSettings = lazy(() => import("./pages/ProjectSettings"));
const TaskCreation = lazy(() => import("./pages/TaskManagement/TaskCreation"));
const NotificationsPage = lazy(
  () => import("./pages/Notifications/NotificationPage"),
);
const EscrowFiles = lazy(() => import("./pages/Notifications/EscrowFiles"));
const Workspaces = lazy(() => import("./pages/Workspaces"));
const ProjectsPage = lazy(() => import("./pages/Projects/ProjectsPage"));
const Search = lazy(() => import("./components/Search/Search"));
const LoginCallback = lazy(
  () => import("./components/LoginCallback/LoginCallback"),
);
const SignInPage = lazy(() => import("./pages/SignIn"));
const CompanySettings = lazy(
  () => import("./pages/CompanySettings/CompanySettings"),
);
const SpaceSettings = lazy(() => import("./pages/SpaceSettings/SpaceSettings"));
const EstimateListPage = lazy(() => import("./pages/EstimateList"));
const Estimate = lazy(() => import("./pages/Estimation/Estimate"));
const Diagram = lazy(() => import("./components/Diagram"));
const Workflow = lazy(() => import("./components/Workflow"));
const SuperAdmin = lazy(() => import("./pages/SuperAdmin"));
const Forbidden = lazy(() => import("./pages/Error/Forbidden"));
const FeedbackToastComponent = lazy(
  () => import("./cubeComponent/html/feedbackToast"),
);
const Risk = lazy(() => import("./pages/RiskManagement"));
const AssetRegister = lazy(() => import("./components/AssetRegister"));
const AssetList = lazy(() => import("./components/AssetList"));
const ChangeOrder = lazy(() => import("./components/ChangeOrder"));
const ScheduleListPage = lazy(
  () => import("./pages/Schedules/ScheduleListPage"),
);
const GeneralFormsPage = lazy(() => import("./pages/Forms/generalForms"));
const ContractsPage = lazy(() => import("./pages/Contracts"));
const ContractDetails = lazy(
  () => import("./pages/Contracts/ContractDetails.jsx"),
);
const InspectionListPage = lazy(() => import("./pages/Forms/inspection"));
const ProgressUpdatesFormPage = lazy(
  () => import("./pages/Forms/progressUpdates"),
);
const ChangeOrderFormPage = lazy(() => import("./pages/Forms/changeOrder"));
const BillFormPage = lazy(() => import("./pages/Forms/bills"));
const PermitToWorkFormPage = lazy(() => import("./pages/Forms/permitToWork"));
const PaymentsFormPage = lazy(() => import("./pages/Forms/payments"));
const ResourceTypeList = lazy(
  () => import("./components/Resource/ResourceType"),
);
const ResourceList = lazy(() => import("./components/Resource"));
const GoogleSigninButton = lazy(
  () => import("./components/GoogleSigninButton"),
);
const ThankYou = lazy(() => import("./components/ThankYou"));
const ScheduleDetails = lazy(
  () => import("./components/Schedule/ScheduleDetails"),
);
const TaskTypeList = lazy(() => import("./pages/TaskManagement/TaskType"));
const CreateProject = lazy(
  () => import("./components/ProjectCreation/CreateProjectModal"),
);
const ClashTests = lazy(() => import("./pages/ClashTests"));
const ClashTestDetails = lazy(() => import("./components/ClashTests/Details"));
const FututreModuleIllustration = lazy(
  () =>
    import("./components/FututreModuleIllustration/FututreModuleIllustration"),
);
const ProjectFiles = lazy(() => import("./pages/Files/index2"));
const PortalFiles = lazy(() => import("./pages/Files/PortalFiles"));
const UserPortalAccessDenied = lazy(
  () => import("./components/UserPortalAccessDenied"),
);
const CreateInspectionAndProgres = lazy(
  () => import("./pages/CreateInspectionAndProgress"),
);
const EstimatePortalList = lazy(
  () => import("./pages/Estimation/EstimateList"),
);
const PortalExpired = lazy(
  () => import("@Components/PortalExpired/PortalExpired"),
);
const ReviewPage = lazy(() => import("./pages/Reviews/Review"));
const Mailbox = lazy(() => import("./pages/Mailbox/index"));
const Reviews = lazy(() => import("./pages/Reviews/index.jsx"));
const MailThread = lazy(() => import("./pages/Mailbox/MailThread"));
const PdfGenerator = lazy(
  () => import("./components/PdfGenerator/PdfGenerator"),
);
const FileSync = lazy(() => import("./components/FilesSync"));

const isSuperAdminUrl = window.location.host.startsWith("config.");

function Router() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { licenceStatus, userDetails, showStickySidebar, fileSyncState } =
    useSelector((state) => state);
  const [isPortalExpired, setIsPortalExpired] = useState(false);
  const status = localStorage.getItem("isLogedIn");
  const location = useLocation();
  const navigate = useNavigate();
  const [renderHeader, setRenderHeader] = useState(false);
  const [renderHomePage, setRenderHomePage] = useState(false);
  const [homePageUrl] = getHomePageUrl();
  const [showUserPortalAccessDenied, setShowUserPortalAccessDenied] =
    useState(false);
  const portalData = useSelector((state) => state.portalData);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [showLogoutError, setShowLogoutError] = useState("");
  const [showOldBrowserWarning, setShowOldBrowserWarning] = useState(false);

  const detectMobileDevice = () => {
    if (
      !["techm-prod", "techm-preprod", "techm-dev"].includes(
        process.env.REACT_APP_ENV,
      ) &&
      !window.location.host.includes("dpmsportal")
    ) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ) &&
        !window.location.href.includes("mobileExplorer")
      ) {
        setIsMobileDevice(true);
      } else {
        setIsMobileDevice(false);
      }
    }
  };

  useEffect(() => {
    const errorMessage = searchParams.get("errorMessage");
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("errorMessage");

    if (errorMessage) {
      setSearchParams(newSearchParams);
      setShowLogoutError(decodeURIComponent(errorMessage));
    }
  }, [searchParams.get("errorMessage")]);

  useEffect(() => {
    const projectId = searchParams.get("projectId");
    if (projectId) {
      dispatch(getCurrProjectData(projectId));
    }
  }, [searchParams.get("projectId")]);

  useEffect(() => {
    if (
      portalData.status == "EXPIRED" &&
      !location.pathname.includes("subscription") &&
      location.pathname != "/"
    ) {
      setIsPortalExpired(true);
    } else {
      setIsPortalExpired(false);
    }
  }, [portalData, location]);

  useEffect(() => {
    // TODO: Safe check in case url contains null. will remove after may 2024 if code stables
    if (
      location.pathname.includes("null") ||
      location.search.includes("null")
    ) {
      window.location.href = "/";
    }
  }, [location]);

  useEffect(() => {
    if (renderHomePage && homePageUrl) {
      // TODO: Copied from signin form page
      const searchParams = new URLSearchParams(location.search);

      let redirectUrl = homePageUrl;
      if (searchParams.get("redirectUrl")) {
        redirectUrl = decodeURIComponent(searchParams.get("redirectUrl"));
        try {
          const url = new URL(redirectUrl);
          if (url.origin == process.env.REACT_APP_CUBE_BACKEND) {
            window.location.href = redirectUrl;
          }
        } catch (e) {
          navigate(redirectUrl);
        }
      }
      navigate(redirectUrl);
    }
  }, [renderHomePage, homePageUrl]);

  const init = () => {
    if (status == "true") {
      dispatch(
        getUserPermissions({
          resourceType: ["RESOURCE_TYPE_PORTAL"],
        }),
      );
    }

    if (!location.pathname.includes("googleSigninButton")) {
      dispatch(fetchLayout());
      dispatch(getPortalData());
    }
  };

  useEffect(() => {
    // updateSupportChatProperties();
    init();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_SENTRY_DSN && userDetails.id) {
      Sentry.setUser({ email: userDetails.email, id: userDetails.id });
    }
    if (userDetails.id) {
      dispatch(getPortalObject());
    }
  }, [userDetails]);

  const checkIfUserIsPartOfPortal = async () => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/accounts/isUserPartOfPortal`,
    );
    if (!response.data.status) {
      setShowUserPortalAccessDenied(true);
    }
  };

  const checkBrowserCompatibility = () => {
    if (
      typeof Array.prototype.at != "function" ||
      typeof Array.prototype.toSorted != "function"
    ) {
      setShowOldBrowserWarning(true);
    }
  };
  useEffect(() => {
    if (!userDetails.id && status == "true") {
      dispatch(getUserDetails());
      checkIfUserIsPartOfPortal();
    }
    checkBrowserCompatibility();
  }, []);

  useEffect(() => {
    detectMobileDevice();
  }, [location]);

  useEffect(() => {
    if (location.pathname != "/") {
      setShowLogoutError("");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/" && status === "true") {
      setRenderHomePage(true);
      return;
    }
    if (
      location.pathname !== "/" &&
      status !== "true" &&
      !location.pathname.includes("thank-you") &&
      !location.pathname.includes("googleSigninButton") &&
      !location.pathname.includes("googleLoginCallback") &&
      !location.pathname.includes("resetPassword") &&
      !location.pathname.includes("95789") &&
      !location.pathname.includes("signup") &&
      !location.pathname.includes("invitation") &&
      !location.pathname.includes("pdfExport")
    ) {
      navigate(
        `/?redirectUrl=${encodeURIComponent(
          window.location.pathname + window.location.search,
        )}${window.location.hash}`,
      );
      return;
    }

    const matchRoute = (pathname, path) => {
      const regex = new RegExp(`^/?${path.replace(/:[^\s/]+/g, "([\\w-]+)")}$`);
      return regex.test(pathname);
    };

    const currentRoute = routes.find((route) =>
      route.path.some((path) => matchRoute(location.pathname, path)),
    );

    if (currentRoute?.showStickySidebar === false) {
      dispatch(setShowStickySidebar(false));
    }
    if (currentRoute?.showHeader === false) {
      setRenderHeader(false);
    } else {
      setRenderHeader(true);
    }
  }, [location.pathname]);

  const routes = [
    {
      path: ["/", "/resetPassword"],
      element: <SignInPage />,
      exact: true,
      showStickySidebar: false,
      showHeader: false,
    },
    {
      path: ["/googleSigninButton"],
      element: <GoogleSigninButton />,
      exact: true,
      showStickySidebar: false,
      showHeader: false,
    },
    {
      path: ["/thank-you"],
      element: <ThankYou />,
      exact: true,
      showStickySidebar: false,
      showHeader: false,
    },
    {
      path: ["/signup", "/95789"],
      element: <SignInPage formType="signup" />,
      showStickySidebar: false,
      showHeader: false,
    },
    {
      path: ["/invitation"],
      element: <SignInPage formType="invitation" />,
      showStickySidebar: false,
      showHeader: false,
    },
    {
      path: ["/user-profile/:settingType"],
      element: <UserProfile />,
      showStickySidebar: false,
    },
    {
      path: ["/dashboard"],
      element: isSuperAdminUrl ? <SuperAdmin /> : <PortalDashboard />,
      showStickySidebar: false,
      showHeader: false,
    },
    {
      path: ["/welcome"],
      element: <Welcome />,
      showStickySidebar: false,
      showHeader: false,
    },
    {
      path: ["/subscriptionWarning"],
      element: <SubscriptionWarning />,
      showStickySidebar: false,
      showHeader: false,
    },
    {
      path: ["/googleLoginCallback"],
      element: <LoginCallback />,
      showStickySidebar: false,
    },
    {
      path: ["/projects"],
      element: <ProjectsPage />,
      showStickySidebar: false,
    },
    { path: ["/403"], element: <Forbidden />, showStickySidebar: false },
    { path: ["/project/:projectId/mails"], element: <Mailbox /> },
    { path: ["/reviews"], element: <Reviews /> },
    {
      path: ["/project/:projectId/mails/:mailId/mailThread"],
      element: <MailThread />,
    },
    { path: ["/workspace/:workspaceId/explorer"], element: <FileExplorer /> },
    {
      path: ["/workspace/:workspaceId/mobileExplorer"],
      element: <MobileExplorer />,
      showStickySidebar: false,
      showHeader: false,
    },
    { path: ["/Files"], element: <PortalFiles /> },
    {
      path: ["/workspace/:workspaceId/file/:fileid/issues"],
      element: <Issues />,
    },
    { path: ["/project/:projectId/issues"], element: <Issues /> },
    { path: ["/issuesList"], element: <Issues /> },
    { path: ["/project/:projectId/setting"], element: <ProjectSettings /> },
    {
      path: ["/spaceSettings/:workspaceId/:settingType?"],
      element: <SpaceSettings />,
    },
    {
      path: ["/project/:projectId/createTransmittals"],
      element: <Transmitals />,
    },
    {
      path: ["/project/:projectId/listTransmittals"],
      element: <TransmitalsList />,
      exact: true,
    },
    {
      path: ["/project/:projectId/listFiles"],
      element: <ProjectFiles />,
      exact: true,
    },
    {
      path: ["/project/:projectId/EstimateList"],
      element: <EstimateListPage />,
    },
    {
      path: ["/project/:projectId/listTransmittals/:transmittalVersionId"],
      element: <TransmitalDetails />,
    },
    {
      path: ["/project/:projectId/estimate/:estimateId"],
      element: <Estimate />,
      exact: true,
    },
    {
      path: ["/project/:projectId/estimate/:estimateId/version/:versionId"],
      element: <Estimate />,
      exact: true,
    },
    { path: ["/transmittals"], element: <TransmitalsList /> },
    { path: ["/project/:projectId/createTasks"], element: <TaskCreation /> },
    {
      path: ["/project/:projectId/editTask/:taskId"],
      element: <TaskCreation />,
    },
    {
      path: ["/project/:projectId/editTask/:taskId/schedule/:scheduleId"],
      element: <TaskCreation />,
    },
    {
      path: ["/project/:projectId/schedule/:scheduleId/createTasks"],
      element: <TaskCreation />,
    },
    {
      path: [
        "/project/:projectId/schedule/:scheduleId/spaceId/:spaceId/createTasks",
      ],
      element: <TaskCreation />,
    },
    {
      path: ["/project/:projectId/listTask"],
      element: <TaskList />,
      exact: true,
    },
    {
      path: ["/project/:projectId/forms/:resourceId"],
      element: <CreateInspectionAndProgres />,
      exact: true,
    },
    {
      path: ["/project/:projectId/listTask/:taskId"],
      element: <TaskDetails />,
    },
    { path: ["/tasks"], element: <TaskList /> },
    {
      path: ["/portalSettings/:settingType"],
      element: <PortalSetting />,
      exact: true,
      showStickySidebar: false,
    },
    {
      path: ["/portalSettings/:settingType/:formId/:formCategory"],
      element: <PortalSetting />,
      exact: true,
      showStickySidebar: false,
    },
    {
      path: ["/portalSettings/:settingType/:dsrId"],
      element: <PortalSetting />,
      exact: true,
      showStickySidebar: false,
    },
    {
      path: ["/portalSettings/:settingType/:dsrId/dsrVersion/:dsrVersionId"],
      element: <PortalSetting />,
      exact: true,
      showStickySidebar: false,
    },
    {
      path: ["/companySettings/:companyId/:settingType?"],
      element: <CompanySettings />,
      showStickySidebar: false,
    },
    {
      path: ["/project/:projectId/projectdashboard"],
      element: <ProjectDashboard />,
    },
    { path: ["/project/:projectId/Workspaces"], element: <Workspaces /> },
    { path: ["/search"], element: <Search /> },
    {
      path: ["/project/:projectId/contractsList"],
      element: <ContractsPage />,
      exact: true,
    },
    {
      path: ["/project/:projectId/contract/:contractId"],
      element: <ContractDetails />,
      exact: true,
    },
    {
      path: ["/project/:projectId/generalFormsList"],
      element: <GeneralFormsPage />,
      exact: true,
    },
    {
      path: ["/project/:projectId/inspectionFormsList"],
      element: <InspectionListPage />,
      exact: true,
    },
    {
      path: ["/project/:projectId/progressUpdatesFormsList"],
      element: <ProgressUpdatesFormPage />,
      exact: true,
    },
    {
      path: ["/project/:projectId/changeOrderFormsList"],
      element: <ChangeOrderFormPage />,
      exact: true,
    },
    {
      path: ["/project/:projectId/billsFormsList"],
      element: <BillFormPage />,
      exact: true,
    },
    {
      path: ["/project/:projectId/paymentsFormsList"],
      element: <PaymentsFormPage />,
      exact: true,
    },
    {
      path: ["/project/:projectId/permitToWorkFormsList"],
      element: <PermitToWorkFormPage />,
      exact: true,
    },
    {
      path: ["/project/:projectId/space/:spaceId/gantt"],
      element: <Gantt />,
      exact: true,
    },
    {
      path: ["/project/:projectId/scheduleList"],
      element: <ScheduleListPage />,
      exact: true,
    },
    {
      path: ["/project/:projectId/schedule/:scheduleId/ScheduleDetails"],
      element: <ScheduleDetails />,
      exact: true,
    },
    {
      path: ["/project/:projectId/resourceTypeList"],
      element: <ResourceTypeList />,
      exact: true,
    },
    {
      path: ["/project/:projectId/resourceList"],
      element: <ResourceList />,
      exact: true,
    },
    {
      path: ["/project/:projectId/changeOrder"],
      element: <FututreModuleIllustration moduleName="Change Order" />,
      exact: true,
    },
    {
      path: ["/project/:projectId/assetRegister/:assetRegisterId"],
      element: <AssetList />,
      exact: true,
    },
    {
      path: ["/project/:projectId/workflow/:workflowId/diagram"],
      element: <Diagram />,
    },
    {
      path: ["/project/:projectId/workflow"],
      element: <FututreModuleIllustration moduleName="Workflow" />,
      exact: true,
    },
    {
      path: ["/designviewer/file/:fileId"],
      element: <DesignViewer />,
      showHeader: false,
      showStickySidebar: false,
    },
    {
      path: ["/project/:projectId/clashTests"],
      element: <ClashTests />,
      exact: true,
    },
    {
      path: ["/project/:projectId/clashDetails/:clashDetectionId"],
      element: <ClashTestDetails />,
    },
    {
      path: ["/project/:projectId/riskManagement"],
      element: <FututreModuleIllustration moduleName="Risks" />,
    },
    {
      path: ["/project/:projectId/tendersList"],
      element: <FututreModuleIllustration moduleName="Tenders" />,
    },
    {
      path: ["/project/:projectId/assetRegister"],
      element: <FututreModuleIllustration moduleName="Assets" />,
    },
    {
      path: ["/project/:projectId/procurement"],
      element: <FututreModuleIllustration moduleName="Procurement" />,
    },
    {
      path: ["/notifications"],
      element: <NotificationsPage />,
      showStickySidebar: false,
    },
    {
      path: ["/escrowFiles/:batchId"],
      element: <EscrowFiles />,
      showStickySidebar: false,
    },
    {
      path: ["/project/createNewProject"],
      element: <CreateProject />,
      exact: true,
    },
    {
      path: ["/estimatePortalList"],
      element: <EstimatePortalList />,
      exact: true,
      showStickySidebar: false,
    },
    { path: ["/review"], element: <ReviewPage />, exact: true },
    {
      path: [":module/pdfExport"],
      element: <PdfGenerator />,
      showStickySidebar: false,
      showHeader: false,
    },
  ];

  return (
    <>
      {showLogoutError && (
        <div className="flex justify-around text-white p-1 bg-system_colour_3">
          {showLogoutError}
        </div>
      )}
      <Suspense fallback={<></>}>
        <FeedbackToastComponent />
      </Suspense>

      {showOldBrowserWarning && (
        <Suspense fallback={<></>}>
          <OldBrowserWarning />
        </Suspense>
      )}

      {isMobileDevice && (
        <Suspense fallback={<></>}>
          <MobileDeviceRestrict />
        </Suspense>
      )}

      <Suspense fallback={<></>}>
        <FileSync />
      </Suspense>
      <div
        className={`${
          !location.pathname.includes("pdfExport") && css.controlSection
        }`}
      >
        {!licenceStatus && <LicenceWarning />}
        <PaymentPlanWarning />
        {isPortalExpired == true && (
          <Suspense fallback={<></>}>
            <PortalExpired />
          </Suspense>
        )}
        {showUserPortalAccessDenied && (
          <Suspense fallback={<></>}>
            <UserPortalAccessDenied />
          </Suspense>
        )}
        {renderHeader && <Header />}
        <MinimizeFloatter />
        {status == "true" && <Notification />}

        <div className="flex overflow-auto h-full w-full">
          {showStickySidebar && <StickySidebar />}
          <div className="w-full h-full overflow-auto">
            <ErrorBoundary location={location}>
              <Routes>
                {routes.flatMap(({ path, element, exact = false }) =>
                  path.map((singlePath) => (
                    <Route
                      key={singlePath}
                      path={singlePath}
                      exact={exact}
                      element={<Suspense>{element}</Suspense>}
                    />
                  )),
                )}
              </Routes>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </>
  );
}

export default Router;
