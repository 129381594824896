export function fileSyncState(
  state = {
    minimiseState: "CLOSED_FILE_SYNC",
    data: {},
  },
  action,
) {
  switch (action.type) {
    case "MINIMISED_FILE_SYNC":
      return {
        ...state,
        minimiseState: "MINIMISED_FILE_SYNC",
      };

    case "MAXIMISED_FILE_SYNC":
      return {
        ...state,
        minimiseState: "MAXIMISED_FILE_SYNC",
      };

    case "CLOSED_FILE_SYNC":
      return {
        ...state,
        minimiseState: "CLOSED_FILE_SYNC",
      };

    case "SET_FILE_SYNC_DATA": {
      if (state.minimiseState == "CLOSED_FILE_SYNC") {
        return {
          ...state,
          minimiseState: "MINIMISED_FILE_SYNC",
          data: action.data,
        };
      }

      return {
        ...state,
        data: action.data,
      };
    }

    default:
      return state;
  }
}
