import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { getNameInitials } from "../constants/globalFunctions";
import { CONSTANTS } from "../constants/globalVariable";
import IconComponent from "../icons";
import { ThemeContext } from "../../ProviderApp";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserProfileModal from "../../components/UserProfileModalComponent";
import { fetchUserTagData } from "../../actions/setUserTagData";
import ProviderApp from "../../ProviderApp";
import AvatarComponent from "./AvatarComponent";

function UserNameTagComponent({
  id,
  handleDeleteIconClicked,
  background = "var(--surface-white)",
  showAvatarOnly = false,
  size,
  isCircle,
  isSquare,
  bgColor = "var(--system-colour-2)",
  style,
  maxAvatars,
  userIdsForGroup,
}) {
  return (
    <ProviderApp>
      <UserNameTagComponentWithProvider
        id={id}
        background={background}
        handleDeleteIconClicked={handleDeleteIconClicked}
        showAvatarOnly={showAvatarOnly}
        size={size}
        isCircle={isCircle}
        isSquare={isSquare}
        bgColor={bgColor}
        style={style}
        maxAvatars={maxAvatars}
        userIdsForGroup={userIdsForGroup}
      />
    </ProviderApp>
  );
}

function UserNameTagComponentWithProvider({
  id,
  handleDeleteIconClicked,
  background,
  showAvatarOnly,
  size,
  isCircle,
  isSquare,
  bgColor = "var(--system-colour-2)",
  style,
  maxAvatars,
  userIdsForGroup,
}) {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { userTagData } = useSelector((state) => state);

  const [showUserProfileModal, setShowUserProfileModal] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setShowUserProfileModal(true);
  };

  const onClose = () => {
    setShowUserProfileModal(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchUserTagData(id));
    }
  }, [id]);

  const getGroupedAvatars = (ids) => {
    const userAvatars = ids.map((id) => (
      <AvatarComponent
        key={id}
        showAvatarOnly
        id={id}
        size={size}
        isCircle
        bgColor={bgColor}
      />
    ));
    return userAvatars;
  };

  let groupedAvatars;

  if (maxAvatars) {
    groupedAvatars = getGroupedAvatars(userIdsForGroup);
  }

  return (
    <>
      {_.get(userTagData, `${id}.isLoaded`) &&
        (showAvatarOnly ? (
          <AvatarComponent
            bgColor={bgColor}
            size={`${size}`}
            isCircle={isCircle}
            isSquare={isSquare}
            id={id}
            style={style}
          />
        ) : (
          <Chip
            label={userTagData[id].data.fullName}
            deleteIcon={<IconComponent name="cross" />}
            size="medium"
            avatar={
              userTagData[id].data.profilePic ? (
                userTagData[id].data.profilePicChanged ? (
                  <Avatar src={userTagData[id].data.profilePic} />
                ) : (
                  <Avatar
                    src={`${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getImage/${CONSTANTS.PROFILE}/${id}`}
                  />
                )
              ) : (
                <Avatar>
                  <div className="text-white">
                    {getNameInitials({
                      firstName: userTagData[id].data.firstName,
                      lastName: userTagData[id].data.lastName,
                    })}
                  </div>
                </Avatar>
              )
            }
            onDelete={handleDeleteIconClicked}
            onClick={handleClick}
            sx={{
              background: `var(${theme.userNameTagComponentBackground})`,
              color: `var(${theme.rootTextColor})`,
              // TODO: tag component label not working inside syncufusion grid component. Will remove it later when we remove syncufsion grids
              lineHeight: "normal",
            }}
          />
        ))}
      {showUserProfileModal && (
        <UserProfileModal userId={id} onClose={onClose} />
      )}

      {maxAvatars && (
        <AvatarComponent
          style={style}
          bgColor={bgColor}
          size={`${size}`}
          isCircle={isCircle}
          isSquare={isSquare}
          maxAvatars={maxAvatars}
          groupedAvatars={groupedAvatars}
        />
      )}
    </>
  );
}

export default UserNameTagComponent;
