import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MdOutlineSearch } from "react-icons/md";
import { AiFillAudio, AiFillPauseCircle } from "react-icons/ai";
import css from "./index.module.css";
import BreadCrumbNav from "../BreadCrumbNav/BreadCrumbNav";
import getHomePageUrl from "../../services/hooks/getHomePageUrl";
import AvatarComponent from "../../cubeComponent/html/avatar";
import ToolTipComponent from "../../cubeComponent/html/tooltip";
import NotificationIcon from "../Notification/NotificationIcon";
import ProfileSideBar from "./profileSideBar";
import { useNavigate } from "react-router-dom";
import GlobalSearch from "../PortalDashboard/GlobalSearch";
import UserNameTagComponent from "../../cubeComponent/html/UserNameTagComponent";

function Header({ hideSearch = false, hideNotification = false }) {
  const navigate = useNavigate();
  const layout = useSelector((state) => state.layout);
  const portalData = useSelector((state) => state.portalData);

  const [homePageUrl] = getHomePageUrl();
  const [showSidebar, setShowSidebar] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const [audioStart, setAudioStart] = useState(false);
  const [audioSuggestion, setAudioSuggestion] = useState("");
  const [recognitionHandler, setRecognitionHandler] = useState(null);
  const [userInitials, setUserInitials] = useState("");

  const logoClick = () => {
    navigate(homePageUrl);
  };

  useEffect(() => {
    if (userDetails.firstName) {
      let firstName = userDetails.firstName[0].toUpperCase();
      const lastName = userDetails.lastName[0]?.toUpperCase();
      if (lastName) {
        firstName += lastName;
      }
      setUserInitials(firstName);
    }
  }, [userDetails]);

  const getProfile = (type) => {
    return (
      <UserNameTagComponent
        showAvatarOnly
        id={userDetails._id}
        size={type}
        isCircle
      />
    );
  };

  useEffect(() => {
    if (audioSuggestion) {
      const ifSearch = audioSuggestion.search(/search|find/);
      if (ifSearch != -1) {
        const query = audioSuggestion
          .replace("search", "")
          .replace("find", "")
          .replace("please", "")
          .trim();
        navigate(`/search?query=${query}`);
      }
    }
  }, [audioSuggestion]);

  const handleAudioStart = () => {
    if (
      !("SpeechRecognition" in window || "webkitSpeechRecognition" in window)
    ) {
      setAudioSuggestion("Your browser does not support this feature");
      return;
    }

    setAudioStart(true);
    window.SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.interimResults = true;
    recognition.continuous = true;
    recognition.continuous = false;
    recognition.lang = "en-US";
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.onresult = (event) => {
      const result = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      setAudioSuggestion(result);
      setAudioStart(false);
    };

    recognition.onend = (event) => {
      setAudioStart(false);
    };

    recognition.onerror = (event) => {
      setAudioStart(false);
    };

    recognition.onnomatch = (event) => {
      setAudioStart(false);
    };

    recognition.start();
    setRecognitionHandler(recognition);
  };

  const handleAudioStop = () => {
    setAudioStart(false);
    recognitionHandler.stop();
  };

  const handleClose = () => {
    setShowSidebar(false);
  };

  return (
    <>
      {showSidebar === true && <ProfileSideBar handleClose={handleClose} />}
      <div className={`${css.root} ${css.headerWrapper} w-full`}>
        <div className={css.leftSection}>
          <div className={`${css.logoBox} ${css.headerElement}`}>
            {Object.keys(layout).length ? (
              <img
                style={{ cursor: "pointer" }}
                onClick={logoClick}
                className={css.logo}
                src={
                  layout.ALLOW_PORTAL_LOGO_UPDATE && portalData.logo
                    ? portalData.logoUrl
                    : process.env.REACT_APP_IMAGES_PUBLIC_URL +
                      layout.HEADER_LOGO
                }
                alt="Logo"
              />
            ) : null}
          </div>
          <div className={css.breadCrumbWrapper}>
            <BreadCrumbNav />
          </div>
        </div>
        <div className={css.rightSection}>
          {["qa", "development"].includes(process.env.REACT_APP_ENV) ? (
            <div className="audioAi" data-testid="audioAI">
              <div className="suggestions" style={{ position: "relative" }}>
                <span className={css.suggestion}>{audioSuggestion}</span>
              </div>
              {audioStart == false && (
                <AiFillAudio
                  style={{ cursor: "pointer" }}
                  size={18}
                  color="red"
                  onClick={handleAudioStart}
                />
              )}
              {audioStart == true && (
                <AiFillPauseCircle
                  style={{ cursor: "pointer" }}
                  size={18}
                  color="red"
                  onClick={handleAudioStop}
                />
              )}
            </div>
          ) : null}

          {layout.SHOW_GLOBAL_SEARCH && !hideSearch && (
            <div
              className={`cursor-pointer pr-2 text-#192048 items-center flex ${css.element}`}
              data-testid="searchIcon"
            >
              <GlobalSearch />
            </div>
          )}

          {!hideNotification && (
            <div
              className={`${css.notificationsIcon} ${css.element}`}
              data-testid="notificationIcon"
            >
              <NotificationIcon />
            </div>
          )}

          <div className={`${css.profilePicture} ${css.element}`}>
            <div
              className={css.avatarInitialsSmall}
              onClick={() => setShowSidebar(true)}
              data-testid="avatarInitialsSmallIcon"
            >
              {getProfile("XS")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
